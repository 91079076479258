import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxMaskModule } from 'ngx-mask';
import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { MaskModule } from '../mask/mask.module';
import { SharedModule } from '../shared/shared.module';
import { FormQuestionRequiredDirective } from './formquestion-required/formquestion-required.directive';
import { FormCheckboxBootstrapComponent } from './formquestion/bootstrap/checkbox/checkbox.component';
import { FormDateInputBootstrapComponent } from './formquestion/bootstrap/date/date-input/date-input.component';
import { FormDateBootstrapComponent } from './formquestion/bootstrap/date/date.component';
import { FormDateRangeInputBootstrapComponent } from './formquestion/bootstrap/daterange/daterange-input/daterange-input.component';
import { FormDateRangeBootstrapComponent } from './formquestion/bootstrap/daterange/daterange.component';
import { FormDropdownBootstrapComponent } from './formquestion/bootstrap/dropdown/dropdown.component';
import { FormFileUploadBootstrapComponent } from './formquestion/bootstrap/fileupload/fileupload.component';
import { FormGroupBootstrapComponent } from './formquestion/bootstrap/formgroup/formgroup.component';
import { FormHiddenBootstrapComponent } from './formquestion/bootstrap/hidden/hidden.component';
import { FormNumericInputBootstrapComponent } from './formquestion/bootstrap/numeric/numeric-input/numeric-input.component';
import { FormNumericBootstrapComponent } from './formquestion/bootstrap/numeric/numeric.component';
import { FormRadioBootstrapComponent } from './formquestion/bootstrap/radio/radio.component';
import { FormRadioButtonBootstrapComponent } from './formquestion/bootstrap/radiobutton/radiobutton.component';
import { FormTextareaBootstrapComponent } from './formquestion/bootstrap/textarea/textarea.component';
import { FormTextboxBootstrapComponent } from './formquestion/bootstrap/textbox/textbox.component';
import { FormTypeheadBootstrapComponent } from './formquestion/bootstrap/typehead/typehead.component';
import { FormQuestionOutletDirective } from './formquestion/formquestion-outlet.directive';
import { FormReCaptchaControlComponent } from './formquestion/recaptcha/recaptcha-control/recaptcha-control.component';
import { FormReCaptchaComponent } from './formquestion/recaptcha/recaptcha.component';
import { FormQuestionErrorBootstrapComponent } from './formquestionerror-bootstrap/formquestionerror-bootstrap.component';
import { FormValidationServiceConfig, IFormValidationProvider } from './formvalidationmessage.service';

/**
 * Form shared module.
 *
 * @export
 * @class FormModule
 */
@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        TypeaheadModule.forRoot(),
        NgxMaskModule.forRoot(),
        PopoverModule,
        SharedModule,
        FontAwesomeModule,
        MaskModule,
    ],
    exports: [
        ReactiveFormsModule,
        NgxMaskModule,
        FormQuestionOutletDirective,
        FormTextboxBootstrapComponent,
        FormTextareaBootstrapComponent,
        FormFileUploadBootstrapComponent,
        FormDropdownBootstrapComponent,
        FormGroupBootstrapComponent,
        FormCheckboxBootstrapComponent,
        FormRadioBootstrapComponent,
        FormHiddenBootstrapComponent,
        FormDateBootstrapComponent,
        FormDateInputBootstrapComponent,
        FormNumericBootstrapComponent,
        FormNumericInputBootstrapComponent,
        FormQuestionErrorBootstrapComponent,
        FormQuestionRequiredDirective,
        FormRadioButtonBootstrapComponent,
        FormTypeheadBootstrapComponent,
        FormReCaptchaComponent,
        FormReCaptchaControlComponent,
        FormDateRangeBootstrapComponent,
        FormDateRangeInputBootstrapComponent,
        MaskModule,
    ],
    declarations: [
        FormQuestionOutletDirective,
        FormTextboxBootstrapComponent,
        FormTextareaBootstrapComponent,
        FormFileUploadBootstrapComponent,
        FormDropdownBootstrapComponent,
        FormGroupBootstrapComponent,
        FormCheckboxBootstrapComponent,
        FormQuestionErrorBootstrapComponent,
        FormRadioBootstrapComponent,
        FormHiddenBootstrapComponent,
        FormDateBootstrapComponent,
        FormDateInputBootstrapComponent,
        FormNumericBootstrapComponent,
        FormNumericInputBootstrapComponent,
        FormQuestionRequiredDirective,
        FormRadioButtonBootstrapComponent,
        FormTypeheadBootstrapComponent,
        FormReCaptchaComponent,
        FormReCaptchaControlComponent,
        FormDateRangeBootstrapComponent,
        FormDateRangeInputBootstrapComponent,
    ],
})
export class FormModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IFormValidationProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof FormModule
     */
    static withConfig(config: IServiceProviderConfig<IFormValidationProvider> = {}): ModuleWithProviders<FormModule> {
        return {
            ngModule: FormModule,
            providers: [{ provide: FormValidationServiceConfig, useValue: config }],
        };
    }
}
