import { EventEmitter, Injectable } from '@angular/core';

/**
 * Enumeration of Bootstrap resolution types.
 *
 * @export
 * @enum {number}
 */
export enum BootstrapResolutionType {
    XS,
    SM,
    MD,
    LG,
    XL,
    XXL,
}

/**
 * Service used to help knowing bootstrap resolution breakpoints.
 *
 * @export
 * @class BootstrapResolutionService
 */
@Injectable()
export class BootstrapResolutionService {
    private breakpoints: number[];
    private _current: BootstrapResolutionType;
    private timeoutId: number;

    /**
     * The event is broadcast when the resolution breakpoint changes.
     *
     * @type {EventEmitter<BootstrapResolutionType>}
     * @memberof BootstrapResolutionService
     * @example
     *      bootstrapResolutionService.resolutionChange$.subscribe(resolution => {
     *          if (resolution == BootstrapResolutionType.XS) {
     *              // Your code here
     *          }
     *      });
     */
    resolutionChange$: EventEmitter<BootstrapResolutionType>;

    constructor() {
        this.breakpoints = [BootstrapResolutionType.XS, BootstrapResolutionType.SM, BootstrapResolutionType.MD, BootstrapResolutionType.LG, BootstrapResolutionType.XL, BootstrapResolutionType.XXL];

        this.getCurrentBreakpoint();
        this.resolutionChange$ = new EventEmitter<BootstrapResolutionType>();

        //$(window).on('resize', (): void => this.resizeEvent());
    }

    /**
     * The current bootstrap resolution.
     *
     * @readonly
     * @type {BootstrapResolutionType}
     * @memberof BootstrapResolutionService
     * @example
     *      // Focus on XS and SM resolution
     *      if (bootstrapResolutionService.current <= BootstrapResolutionType.SM) {
     *          // Your code here
     *      }
     */
    public get current(): BootstrapResolutionType {
        return this._current;
    }

    /**
     * Test if the current resolution is in extra small (Phone).
     *
     * @returns {boolean}
     *
     * @memberof BootstrapResolutionService
     * @example
     *      // Focus on XS resolution
     *      if (bootstrapResolutionService.isXS()) {
     *          // Your code here
     *      }
     */
    isXS(): boolean {
        return this.current === BootstrapResolutionType.XS;
    }

    /**
     * Test if the current resolution is in small (Tablet).
     *
     * @returns {boolean}
     *
     * @memberof BootstrapResolutionService
     * @example
     *      // Focus on XS resolution
     *      if (bootstrapResolutionService.isSM()) {
     *          // Your code here
     *      }
     */
    isSM(): boolean {
        return this.current === BootstrapResolutionType.SM;
    }

    /**
     * Test if the current resolution is in medium (Small screen desktop).
     *
     * @returns {boolean}
     *
     * @memberof BootstrapResolutionService
     * @example
     *      // Focus on XS resolution
     *      if (bootstrapResolutionService.isMD()) {
     *          // Your code here
     *      }
     */
    isMD(): boolean {
        return this.current === BootstrapResolutionType.MD;
    }

    /**
     * Test if the current resolution is in large (Large screen desktop).
     *
     * @returns {boolean}
     *
     * @memberof BootstrapResolutionService
     * @example
     *      // Focus on XS resolution
     *      if (bootstrapResolutionService.isLG()) {
     *          // Your code here
     *      }
     */
    isLG(): boolean {
        return this.current === BootstrapResolutionType.LG;
    }

    /**
     * Test if the current resolution is in large (Large screen desktop).
     *
     * @returns {boolean}
     *
     * @memberof BootstrapResolutionService
     * @example
     *      // Focus on XL resolution
     *      if (bootstrapResolutionService.isXL()) {
     *          // Your code here
     *      }
     */
    isXL(): boolean {
        return this.current === BootstrapResolutionType.XL;
    }

    /**
     * Test if the current resolution is in large (Large screen desktop).
     *
     * @returns {boolean}
     *
     * @memberof BootstrapResolutionService
     * @example
     *      // Focus on XXL resolution
     *      if (bootstrapResolutionService.isXXL()) {
     *          // Your code here
     *      }
     */
    isXXL(): boolean {
        return this.current === BootstrapResolutionType.XXL;
    }

    public resizeEvent(): void {
        // Cancel the previous timeout to dispatch the event only once.
        clearTimeout(this.timeoutId);

        // Wait half a second before dispatching the event.
        this.timeoutId = setTimeout(() => {
            const oldBreakpoint: number = this._current;
            this.getCurrentBreakpoint();
            if (oldBreakpoint !== this._current) {
                this.resolutionChange$.emit(this._current);
            }
        }, 500) as any;
    }

    private getCurrentBreakpoint(): void {
        let element: HTMLDivElement;
        let result: BootstrapResolutionType;
        const resolution = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

        this.breakpoints.forEach((breakpoint: BootstrapResolutionType): void => {
            element = document.createElement('div');
            if (breakpoint === 0) {
                element.className = `d-block d-${resolution[breakpoint + 1]}-none`;
            } else if (breakpoint === resolution.length - 1) {
                element.className = `d-none d-${resolution[breakpoint]}-block`;
            } else {
                element.className = `d-none d-${resolution[breakpoint]}-block d-${resolution[breakpoint + 1]}-none`;
            }
            document.body.appendChild(element);

            if (this.isVisible(element)) {
                result = breakpoint;
            }

            document.body.removeChild(element);
        });

        this._current = result;
    }

    private isVisible(el: Element) {
        let style = window.getComputedStyle(el);
        return style.display !== 'none';
    }
}
