import { FormQuestionType } from '../form.service';
import { FormQuestionControlBase, IFormQuestionControlBase } from './formquestioncontrol-base';
import { FormQuestionControlInputBase } from './formquestioncontrol-input-base';

/**
 * Interface to define a file upload question.
 *
 * @export
 * @interface IFormFileUploadQuestion
 * @extends {IFormQuestionControlBase<string>}
 */
export interface IFormFileUploadQuestion extends IFormQuestionControlBase<string> {
    /**
     * Upload multiple files.
     *
     * @type {boolean}
     * @memberof IFormFileUploadQuestion
     */
    multiple?: boolean;

    /**
     * Text on the upload button.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    uploadButtonText?: string;

    /**
     * Accepted file types.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    accept?: string;

    /**
     * Restrict file types.
     *
     * @type {('png' | 'jpg' | 'gif' | 'pdf')[]}
     * @memberof IFormFileUploadQuestion
     */
    restrictFileTypes?: ('png' | 'jpg' | 'jpeg' | 'gif' | 'pdf')[]; // | 'doc' | 'docx' | 'xsl' | 'xlsx' | 'html')[];
    // For the commented types, know that some security checks must be done to ensure that the file is not malicious. Block microsoft office files with macros and HTML files that are not DOM purified.

    /**
     * The size limit in KB (1024 bytes) of the data to upload.
     *
     * @type {number}
     * @memberof IFormFileUploadQuestion
     */
    sizeLimit?: number;

    /**
     * Message added when the size limit is reached.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    sizeLimitMessage?: string;

    /**
     * Send the data in Base 64
     *
     * @type {boolean}
     * @memberof IFormFileUploadQuestion
     */
    base64Data?: boolean;

    /**
     * Display the preview.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    displayPreview?: boolean;

    disabled?: boolean;
}

/**
 * File upload question type.
 *
 * @export
 * @class FormFileUploadQuestion
 * @extends {FormQuestionControlBase<string>}
 */
export class FormFileUploadQuestion extends FormQuestionControlInputBase<string> {
    override formQuestionType = FormQuestionType.FileUpload;

    /**
     * Upload multiple files.
     *
     * @type {boolean}
     * @memberof IFormFileUploadQuestion
     */
    multiple: boolean;

    /**
     * Text on the upload button.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    uploadButtonText: string;

    /**
     * Accepted file types.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    accept: string;

    /**
     * Restrict file types.
     *
     * @type {('png' | 'jpg' | 'gif' | 'pdf')[]}
     * @memberof IFormFileUploadQuestion
     */
    restrictFileTypes: ('png' | 'jpg' | 'jpeg' | 'gif' | 'pdf')[]; // | 'doc' | 'docx' | 'xsl' | 'xlsx' | 'html')[];
    // For the commented types, know that some security checks must be done to ensure that the file is not malicious. Block microsoft office files with macros and HTML files that are not DOM purified.

    /**
     * The size limit in KB (1024 bytes) of the data to upload.
     *
     * @type {number}
     * @memberof IFormFileUploadQuestion
     */
    sizeLimit?: number;

    /**
     * Message added when the size limit is reached.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    sizeLimitMessage?: string;

    /**
     * Send the data in Base 64
     *
     * @type {boolean}
     * @memberof IFormFileUploadQuestion
     */
    base64Data?: boolean;

    /**
     * The data contained in the file to upload.
     *
     * @type {any}
     * @memberof IFormFileUploadQuestion
     */
    data?: any;

    /**
     * Display the preview.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    displayPreview: boolean;

    /**
     * The public file nane.
     *
     * @type {string}
     * @memberof IFormFileUploadQuestion
     */
    fileName: string;

    externalAttachmentNumber: string;
    override disabled: boolean;

    /**
     * Creates an instance of FormFileUploadQuestion.
     * @param {IFormFileUploadQuestion} [options={}]  Creation options.
     *
     * @memberof FormFileUploadQuestion
     */
    constructor(options: IFormFileUploadQuestion = {}) {
        super(options);
        this.multiple = options.multiple || false;
        this.uploadButtonText = options.uploadButtonText || '';
        this.base64Data = options.base64Data != null ? options.base64Data : true;
        this.accept = options.accept;
        this.restrictFileTypes = options.restrictFileTypes;
        this.sizeLimit = options.sizeLimit;
        this.sizeLimitMessage = options.sizeLimitMessage;
        this.displayPreview = options.displayPreview || false;
        this.disabled = options.disabled || false;

        if (this.restrictFileTypes != null && this.accept == null) {
            this.accept = this.restrictFileTypes.map(x => '.' + x).join(',');
        }

        this.onReset = () => {
            if (this.componentRef) {
                this.componentRef['onReset']();
            }
        };
    }

    get type() {
        if (this.data == null || typeof this.data !== 'string') {
            return null;
        }

        if (this.data.indexOf('image/') !== -1) {
            return 'img';
        } else if (this.data.indexOf('application/pdf') !== -1) {
            return 'pdf';
        } else {
            return 'other';
        }
    }
}
