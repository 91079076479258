export const languageHashValues= {
  "GLOBAL": ".2f97cb06d982a00c6e3a",
  "U4302_TRANSACTIONSDETAIL": ".81b0e25619bb5e91217d",
  "U4400_PAYMENT": ".840420fa1e1059623c4c",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".476048c89fd1d4d21d6a",
  "U2117_PADAGREEMENTINVITATION": ".effe45db8b77f6f5faf0",
  "U2000_PAD": ".564bcbb4a9c5fd7c72e0",
  "U4402_INVOICEPORTALPAYMENT": ".e54a53de4edd234c1d7d",
  "U2000_BANKACCOUNTCREATION": ".bf60ef425e98ba9181af",
  "U2011_LOGIN": ".e04a0f830c4739a7921e",
  "U2012_LOGINHELP": ".4710b6423f20175589b6",
  "U2014_SECURITY": ".4326181629bab1aa5ae1",
  "U2086_EFTPENDINGPAYMENTS": ".2f4e53c8f839f3bc06cf",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".a1a9c59a72ff727dfbd7",
  "U2110_ACCOUNTCREATION": ".2129c75f1642a205a45c",
  "U2121_INDIVIDUALPROFILE": ".3f2a67da85d918ab6500",
  "U2131_ORGANIZATIONPROFILE": ".919d5b481bef6c59b894",
  "U2112_ERPBINDING": ".2f46c1f296ca62567b95",
  "U2113_SUPPLIERINVITATION": ".1a394ea779bde79ef41f",
  "U2118_RBBINDING": ".6118e30063a397736eb3",
  "U2132_AFFILIATION": ".7deaa039dd1eff75745b",
  "U2133_BUSINESSLINKS": ".aca3e9dfc04696e8d3b0",
  "U2181": ".0f80ee2ad1b3a5e9ba23",
  "U2211_SUBSCRIPTIONOFFER": ".ac011ea1b5fc47c8c8df",
  "U2212_SUBSCRIPTIONFILTER": ".6795075b6c73f8d9f4df",
  "U2213_SUBSCRIPTION": ".5ccd6eb8b1d63435349d",
  "U4111_CREDITCARDADDITION": ".2c5d3997a0eb6fce2c0d",
  "U4112_CREDITCARDDETAIL": ".2965224512e759ac4fec",
  "U4121_BANKACCOUNTADDITION": ".09056a80b06ba88d47de",
  "U4122_BANKACCOUNTDETAIL": ".e7064beb90990aaef73b",
  "U4303_ANONYMOUSTRANSACTION": ".6c1f6c6fa21d9f452b92",
  "U4401_PAYMENTGATEWAY": ".33e659d736faba0e2c74",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".d900299b3cd5978daadc",
  "U5000_RECEIVABLE": ".40c61ce0effa5878be46",
  "U5101_STATEMENTOFACCOUNT": ".dbe5121975358d6db5b5",
  "U5102_INVOICE": ".aa7ac9da89435e7338b9",
  "U5103_PADAGREEMENT": ".6819014e097204036362",
  "U5121_SENTINVOICE": ".3ebea5f1de76be04dd6f",
  "U5124_PGRECEIVEDPAYMENT": ".2ec775d0950075bd49f9",
  "U5120_INVOICES": ".77312be5c39f710eb525",
  "U5123_PADAGREEMENTCONSOLE": ".8ca939f5cd8654ae0e7e",
  "U5132_PAYMENTRECONCILIATION": ".3f04ad858fa613c04a2f",
  "U5133_BANKRECONCILIATION": ".20197075e52408771e01",
  "U6112_BUSINESSLINK": ".c005301822fff3db1b63",
  "U6113_SUPPLIERRECORD": ".7d004d532976d6069b77",
  "U6211_PAYLOT": ".7575ac139ddbb3eb4de7",
  "U6311_PURCHASE": ".275b00c32c59af5d9d89",
  "U2115_AFFILIATIONINVITATION": ".5ef6f07604f22fdddd9b",
  "U6100_PAYABLE": ".85cb47f98a7ffb7bd335",
  "U6131_SENTPAYMENT": ".fce23ddf3af996efc053",
  "U6191_SENTPAYMENTREPORT": ".092a5e9ffe6a30bad48e",
  "U6221_SENTPAYMENT": ".83163bb70bd3b8f61570",
  "U6422_INTERACSENTPAYMENT": ".de73f15012cb6c70f340",
  "U6531_TRANSACTIONPAYMENT": ".3fec1561c37a3c2869e1",
  "U5131_RECEIVEDPAYMENT": ".9121502d402d810552e9",
  "U5191_RECEIVEDPAYMENTREPORT": ".d5d04530b50db9704431",
  "U2001_HOME": ".941c8468c47dbbdc7eab",
  "U2024_REPORTLOG": ".f0513c6c4434262f0ffd",
  "U2111_INVOICEPORTALBINDING": ".e5dc4ed5c1500bc4fb01",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".680ea456146c1d37c2d7",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".c823690a3a78e87daaae",
  "U6511_TRANSACTIONPARTY": ".e480b9b79f633a7e60ef",
  "U6212_PAYROLLPARTY": ".26e5297d293c0bdb543e",
  "U6121_EFTLOT": ".35386a7129039604ef1f",
  "U6412_INTERACFILE": ".c570b3681602fa59cba3",
  "U6122_EFTAPPROVAL": ".da93a519438c7670f587",
  "U6512_TRANSACTIONLOT": ".781aa31d64ebd0d2283c",
  "U6123_EFTAPPROVAL": ".e0492386184959433d69",
  "U6123_EFTAPPROVALMOBILE": ".7d2c042f605bfa4fcc8f",
  "U2012_LOGIN": ".f586819142437005c7fa",
  "U2000_PAYMENT": ".23810090e8b300cc13d2",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".f9ff2f9d55ed0cc48762",
  "U2000_PAYMENTMODEUPDATE": ".cb88c15ff47ec3fa45eb",
  "U6114_TELUSBILLER": ".6ac4b9b11cabf1064bc3",
  "U4403_CREDITCARDTERMINAL": ".d7ec3ad79e6ddef67809",
  "U4404_INTERACTERMINAL": ".c35f6bb1d3896b8b461d",
  "U2000_SAMPLECHEQUESELECTION": ".a9e5a2a0e52facf34017",
  "U2023_TUTORIALVIDEO": ".ae30f0c0040b489dfbed",
  "U2134_ORGANIZATIONBILLING": ".afe1f2c7b53d2e655d59",
  "U6414_BANKACCOUNTWALLET": ".3d9e66e64c19af5d6e6a",
  "U2022_INSTANTACCOUNTVERIFICATION": ".e2e1836e909ba287ca88",
  "U6532_PENDINGTRANSACTION": ".186386d531159a3f8244",
  "U4301_TRANSACTIONSSUMMARY": ".5730d1cd45bbdee8c262",
  "U5111_SENTINVOICESTRACKING": ".2d40ed8aa052f3cce431",
  "U2026_NETPROMOTERSCORE": ".f20bddd3538473add6c2",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".d81863f112c31e0c8d30",
  "U2000_INTRO": ".6cf74a67807b4275de91",
  "U2000_ELECTRONICSIGNATURE": ".83b03a56e91ccad1fe02",
  "U2000_VERIFYEMAIL": ".f897e209621e9686f6ea",
  "U2200_SUBSCRIPTION": ".e2868d368ae30d1b1f58",
  "U2000_VERIFYIDENTITY": ".19a008cb43bec5539eb5",
  "U2000_ATTACHMENTVIEWER": ".18f4c5ee717bafc8f10e",
  "U2212_PLANLIST": ".3782beb30415269f0778",
  "U3002_TAXBILLSEARCHRESULT": ".d3cc22e269a0d93c8d8b"
}