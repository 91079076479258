import { Validators } from '@angular/forms';
import { FormTextboxQuestion, IFormTextboxQuestion } from '../../gamma/form/formquestion/formquestion-textbox';

export class U2000_LoginUsernameQuestion extends FormTextboxQuestion {
    constructor(options: IFormTextboxQuestion = {}) {
        super(options);
        if (String.isNullOrEmpty(this.key)) {
            this.key = 'username';
        }
        if (String.isNullOrEmpty(this.placeholder)) {
            this.placeholder = 'lblUsername';
        }
        if (String.isNullOrEmpty(this.inputClass)) {
            this.inputClass = 'form-control-sm';
        }
        if (String.isNullOrEmpty(this.width)) {
            this.width = '100%';
        }
        if (this.maxLength == null) {
            this.maxLength = 100;
        }
        if (this.validators != null) {
            if (this.validators.find(x => x === Validators.required) == null) {
                this.validators.push(Validators.required);
            }
        } else {
            this.validators = [Validators.required];
        }

        if (String.isNullOrEmpty(this.label) && this.placeholder !== '') {
            this.labelClass += ' sr-only';
        }

        this.autoComplete = 'on';
        this.hideRequiredMarker = true;

        this.restrict = /^[\'}#%\/=_~@`&\$!.*?+^|{\-\w\d]/;
        this.onPaste = (e: ClipboardEvent) => {
            e.preventDefault();
            const clipboardData = e.clipboardData || window['clipboardData'];
            let pastedText: string = clipboardData.getData('text');
            pastedText = pastedText.replace(/[^\'}#%\/=_~@`&\$!.*?+^|{\-\w\d]/g, String.empty);
            this.formControlRef.setValue(pastedText);
        };
    }
}
