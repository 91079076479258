import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { U2000_SubNavbarService, U2000_TitleService } from '../U2000_core';
import { IU2000_CustomPageParams, U2000_CustomPageService } from './U2000_custompage.service';


@Component({
    selector: 'u2000-custom',
    templateUrl: 'U2000_custompage.component.html',
    styleUrls: ['U2000_custompage.component.scss'],
})
export class U2000_CustomPageComponent implements OnInit {
    public pageParams: IU2000_CustomPageParams;

    constructor(titleService: U2000_TitleService, subNavbarService: U2000_SubNavbarService, protected customPageService: U2000_CustomPageService, protected route: ActivatedRoute) {
        titleService.reset();
        subNavbarService.reset();
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            const customPageParams = this.customPageService.customHistory[params['id']];
            if (customPageParams != null) {
                this.pageParams = customPageParams;
                if (this.pageParams.title == null) {
                    if (this.pageParams.type === 'danger') {
                        this.pageParams.title = 'lblSorry';
                    } else if (this.pageParams.type === 'warning') {
                        this.pageParams.title = 'lblWarning';
                    } else if (this.pageParams.type === 'info') {
                        this.pageParams.title = 'lblInformation';
                    } else if (this.pageParams.type === 'success') {
                        this.pageParams.title = 'lblSuccess';
                    }
                }
            } else if (this.pageParams == null) {
                this.pageParams = {
                    title: 'lblSorry',
                    message: 'U2000-0007',
                    type: 'danger',
                };
            }
        });
    }

    panelClass() {
        return 'card-header ' + this.pageParams.type.toLowerCase();
    }
}
