import { BsCustomDates } from 'ngx-bootstrap/datepicker/themes/bs/bs-custom-dates-view.component';
import { FormQuestionType } from '../form.service';
import { FormDateRangeBootstrapComponent } from './bootstrap/daterange/daterange.component';
import { IFormQuestionConvertValue } from './formquestioncontrol-base';
import { FormQuestionControlInputBase, IFormQuestionControlInputBase } from './formquestioncontrol-input-base';

/**
 * Interface to define a textbox question.
 *
 * @export
 * @interface IFormDaterangeQuestion
 * @extends {IFormQuestionControlBase<string>}
 */
export interface IFormDaterangeQuestion extends IFormQuestionControlInputBase<string> {
    datePickerPlacement?: 'top' | 'bottom' | 'left' | 'right';
    ranges?: CustomDates[];
    keyFrom?: string;
    keyTo?: string;
}

export interface CustomDates extends BsCustomDates {
    key: string;
}

/**
 * Textbox question type.
 *
 * @export
 * @class FormDateQuestion
 * @extends {FormQuestionControlBase<string>}
 */
export class FormDateRangeQuestion extends FormQuestionControlInputBase<string> implements IFormQuestionConvertValue {
    override formQuestionType = FormQuestionType.Date;

    datePickerPlacement: 'top' | 'bottom' | 'left' | 'right';
    ranges: CustomDates[];
    keyFrom: string;
    keyTo: string;

    /**
     * Creates an instance of FormDateQuestion.
     * @param {IFormDateQuestion} [options={}]  Creation options.
     *
     * @memberof FormDateQuestion
     */
    constructor(options: IFormDaterangeQuestion = {}) {
        super(options);
        this.datePickerPlacement = String.isNullOrEmpty(options.datePickerPlacement) ? 'bottom' : options.datePickerPlacement;
        this.ranges = options.ranges;
        this.keyFrom = String.isNullOrEmpty(options.keyFrom) ? 'dateFrom' : options.keyFrom;
        this.keyTo = String.isNullOrEmpty(options.keyTo) ? 'dateTo' : options.keyTo;

        if (options.isInputGroup == null) {
            this.isInputGroup = true;
        }
    }

    buildFormValueFromCriteria(criteria: any, formValue: any): void {
        if (criteria[this.key + 'Preset'] != null && this.componentRef != null) {
            for (let range of (this.componentRef as FormDateRangeBootstrapComponent).dateInput.bsConfig.ranges) {
                if (range['key'] === criteria[this.key + 'Preset']) {
                    formValue[this.key] = range.value;
                }
            }
            delete formValue[this.keyFrom];
            delete formValue[this.keyTo];
        } else if (criteria[this.keyFrom] != null) {
            formValue[this.key] = [formValue[this.keyFrom], formValue[this.keyTo]];

            delete formValue[this.keyFrom];
            delete formValue[this.keyTo];
        }
    }

    buildCriteriaFromForm(formValue: any, criteria: any): void {
        if (formValue[this.key]) {
            const preset = this.getDateRangePreset(formValue[this.key]);
            if (preset != null) {
                criteria[this.key + 'Preset'] = preset;
            }

            if (this.componentRef != null) {
                criteria[this.keyFrom] = (this.componentRef as FormDateRangeBootstrapComponent).datePipe.transform(formValue[this.key][0] as Date, 'medium') as any;
                criteria[this.keyTo] = (this.componentRef as FormDateRangeBootstrapComponent).datePipe.transform(formValue[this.key][1] as Date, 'medium') as any;
            } else {
                criteria[this.keyFrom] = formValue[this.key][0].substr(0, 10) as any;
                criteria[this.keyTo] = formValue[this.key][1].substr(0, 10) as any;
            }
            delete criteria['dates'];
        }
    }

    private getDateRangePreset(dates: Date[]) {
        if (this.componentRef != null) {
            for (let range of (this.componentRef as FormDateRangeBootstrapComponent).dateInput.bsConfig.ranges) {
                if (range.value === dates) {
                    return range['key'];
                }
            }
        }
    }
}
